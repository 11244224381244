import React from 'react';
import './App.css';
import AppRoutes from './AppRoutes';

const App = () => {
  return (
      <div>
     <AppRoutes/>
      </div>

  );
};

export default App; 
