import React from 'react';
import PagesRoute from './pages/PagesRoute';

const AppRoutes = () => {
  return (
    <div>
        <PagesRoute />
    </div>
  )
}

export default AppRoutes;
